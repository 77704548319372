import client from './client';

const OFFER_ID = '5Rf2thizPdoePjLFIcDEfW';

export interface OfferEntry {
    description: string;
    code: string;
    active: boolean;
}

export default async (): Promise<OfferEntry> => {
    const offer = await client.getEntry<OfferEntry>(OFFER_ID);

    return offer.fields;
};
