import * as contentful from 'contentful';

const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: 'k53sl0frm80u',
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: 'nhAk8lFuvU90ZUr7CivNF99E5cUL8opDbh2Ejz921mI',
});

export default client;
