import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PriceFormatter from '../PriceFormatter';
import { SubServiceList } from '../../services';
import { ExpandLess } from '@material-ui/icons';

interface ListComponentProps {
    serviceList: SubServiceList;
}

const useStyles = makeStyles({
    root: {
        margin: '4rem 0',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    innerService: {
        margin: '2rem 0rem 2rem 1rem',
        display: 'flex',
        justifyContent: 'space-between',
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default function ListComponent(props: ListComponentProps): ReactElement<ListComponentProps> {
    const classes = useStyles();
    const {
        serviceList: { name, services },
    } = props;
    const minPrice = services.reduce(
        (currentValue, service) => (service.price > currentValue ? currentValue : service.price),
        Number.MAX_VALUE,
    );
    const [open, setOpen] = useState(false);

    return (
        <>
            <div onClick={() => setOpen((currentOpen) => !currentOpen)} className={classes.root}>
                <Typography variant="subtitle1">{name}</Typography>
                <div className={classes.expandContainer}>
                    <Typography variant="h6">
                        From <PriceFormatter price={minPrice} />
                    </Typography>
                    {open ? <ExpandLess /> : <ExpandMoreIcon />}
                </div>
            </div>
            {open &&
                services.map((service) => (
                    <div key={service.name} className={classes.innerService}>
                        <Typography variant="subtitle1">{service.name}</Typography>
                        <Typography variant="h6">
                            <PriceFormatter price={service.price} />
                        </Typography>
                    </div>
                ))}
        </>
    );
}
