import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Endorsements from '../../components/Endorsements';
import aLiftImg from './alift.jpg';
import blurCover from './blur-cover.jpg';

const useStyles = makeStyles((theme) => ({
    firstBanner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '5rem',
        flexWrap: 'wrap',
        [`& h6`]: {
            width: 500,
            [theme.breakpoints.down('md')]: {
                width: 700,
                marginBottom: '2rem',
            },
            [theme.breakpoints.down('sm')]: {
                width: 600,
            },
            [theme.breakpoints.down('xs')]: {
                width: 300,
            },
        },
        [`& img`]: {
            [theme.breakpoints.down('sm')]: {
                width: 500,
            },
            [theme.breakpoints.down('xs')]: {
                width: 300,
            },
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            marginTop: '2rem',
        },
    },
    secondBanner: {
        padding: '5rem 0',
        marginTop: '5rem',
        backgroundImage: `url("${blurCover}")`,
        backgroundSize: 'cover',
    },
    detailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    detail: {
        width: 500,
        margin: '2rem 0',
        [`& h5`]: {
            fontWeight: 'bold',
        },
    },
}));

const aLiftDetails = [
    {
        heading: 'Research',
        text: `Research has found that by externally applying currents that mimic your body's own electrical frequency, your cells can be recharged, boosting your ATP (adenosine triphosphate) levels - your cells' energy source (think of it as your cells' battery) and triggering the rejuvenation process in you.`,
    },
    {
        heading: 'Immediate results',
        text:
            'In just one 45 minute treatment, you can expect to see a genuine improvement in skin texture, muscle tone, deep lines and wrinkles.',
    },
    {
        heading: 'Reverse signs of aging',
        text:
            'The A-Lift is ideal for anyone, whether you are looking for a natural alternative to botox and fillers, or even if you simply want to make your skin look and feel fantastic. It’s got the power to deliver genuine visible results, yet it’s as gentle and relaxing as a facial.',
    },
    {
        heading: 'Lasting effects',
        text:
            'Results with the A-Lift are immediately visible. For the effects to last longer, however, a short course is required to help maintain ATP levels. After that, we recommend keeping those energy levels topped up around once a month. Your A-Lift Technician will recommend the best course of treatment for your needs.',
    },
];

export default function ALift(): ReactElement {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <Typography align="center" variant="h3">
                    The Celebrity Treatment
                </Typography>
                <Typography align="center" variant="subtitle1">
                    A revolution in age reversal technology Advance &apos;non-surgical facelift&apos; popular with many
                    celebrities
                </Typography>
                <div className={classes.firstBanner}>
                    <Typography component="h6" variant="h6">
                        Celebrity A-Lift Facials treatment uses the revolutionary nano-current technology. Incredibly
                        small electrical currents that are measured in millionths of an amp, which are adjusted to tune
                        in to your body’s natural frequency to combat ageing.
                    </Typography>
                    <img src={aLiftImg} alt="ALift treatment" />
                </div>
            </Container>
            <div className={classes.secondBanner}>
                <Typography align="center" color="inherit" variant="h4">
                    Turns Back Time
                </Typography>
                <Container maxWidth="lg" className={classes.detailsContainer}>
                    {aLiftDetails.map((detail) => {
                        return (
                            <div className={classes.detail} key={detail.heading}>
                                <Typography component="h5" variant="h5">
                                    {detail.heading}
                                </Typography>
                                <Typography variant="subtitle1">{detail.text}</Typography>
                            </div>
                        );
                    })}
                </Container>
            </div>
            <Endorsements
                sonicLink="https://www.sonicmedical.co.uk/a-lift/"
                nouveauLink="https://nouveauskintherapy.co.uk/a-lift/"
            />
        </>
    );
}
