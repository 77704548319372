import * as contentful from 'contentful';
import client from './client';

interface CategoryItem {
    name: string;
    parentCategory: contentful.Entry<unknown>;
}

interface ServiceItem {
    name: string;
    price: number;
    category: contentful.Entry<CategoryItem>;
}

interface Service {
    name: string;
    price: number;
}

export type MappedServices = Record<
    string,
    {
        name: string;
        services: Service[];
        subServices?: MappedServices;
    }
>;

interface CategoryMap {
    parent: string;
    name: string;
    services: {
        name: string;
        price: number;
    }[];
}

/**
 * TODO: This isn't the best way of getting all the entries, as we can't really limit what we get back,
 * we should try and use the graphql features in the future, as we can limit the fields thus decreasing the in memory data
 */
const getServices = async (): Promise<MappedServices> => {
    const serviceEntries = await client.getEntries<ServiceItem>({
        content_type: 'service',
        limit: 500,
    });

    const categoryEntries = await client.getEntries<CategoryItem>({
        content_type: 'category',
        limit: 500,
    });

    if (serviceEntries.errors || categoryEntries.errors) {
        console.error(
            { serviceErrors: serviceEntries.errors, categoryErrors: categoryEntries.errors },
            'Failed to get entries',
        );

        return {};
    }

    return mapServices(serviceEntries, categoryEntries);
};

const mapServices = (
    serviceEntries: contentful.EntryCollection<ServiceItem>,
    categoryEntries: contentful.EntryCollection<CategoryItem>,
): MappedServices => {
    const categoryServicesMap = categoryEntries.items.reduce((categoryMap, categoryEntry) => {
        const id = categoryEntry.sys.id;
        const parent = categoryEntry.fields.parentCategory?.sys?.id;

        return categoryMap.set(id, { parent, name: categoryEntry.fields.name, services: [] });
    }, new Map<string, CategoryMap>());

    serviceEntries.items.forEach((service) => {
        const categoryId = service.fields.category?.sys.id;

        if (!categoryId) {
            console.error({ categoryId, serviceId: service.sys.id }, `Failed to get category id`);

            return;
        }

        const categoryMapEntry = categoryServicesMap.get(categoryId);

        if (!categoryMapEntry) {
            console.error({ categoryId, serviceId: service.sys.id }, `Cannot find category for service`);

            return;
        }

        categoryServicesMap.set(categoryId, {
            ...categoryMapEntry,
            services: [
                ...categoryMapEntry.services,
                {
                    name: service.fields.name,
                    price: service.fields.price,
                },
            ],
        });
    });

    return Array.from(categoryServicesMap).reduce(
        (services, [categoryId, categoryMapValue]) => ({
            ...services,
            ...mapService(services, categoryMapValue, categoryId),
        }),
        {} as MappedServices,
    );
};

const mapService = (services: MappedServices, categoryMapValue: CategoryMap, categoryId: string): MappedServices => {
    const service = services[categoryMapValue.parent || categoryId] || {};

    if (categoryMapValue.parent) {
        // TODO: Sort subservices
        return {
            [categoryMapValue.parent]: {
                ...service,
                subServices: {
                    ...(service.subServices || {}),
                    ...mapService(
                        services,
                        {
                            ...categoryMapValue,
                            // TODO: This means the subServices will only work on 1 level down, need to sort this to enable lower levels
                            parent: '',
                        },
                        categoryId,
                    ),
                },
            },
        };
    } else {
        return {
            [categoryId]: {
                ...service,
                name: categoryMapValue.name,
                services: categoryMapValue.services,
            },
        };
    }
};

export default getServices;
