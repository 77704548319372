import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import Endorsements from '../../components/Endorsements';
import diamondEliteImg from './diamondElite.png';
import blurCover from './blur-cover.jpg';

const useStyles = makeStyles((theme) => ({
    firstBanner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '5rem',
        flexWrap: 'wrap',
        [`& h6`]: {
            width: 500,
            [theme.breakpoints.down('md')]: {
                width: 700,
                marginBottom: '2rem',
            },
            [theme.breakpoints.down('sm')]: {
                width: 600,
            },
            [theme.breakpoints.down('xs')]: {
                width: 300,
            },
        },
        [`& img`]: {
            [theme.breakpoints.down('sm')]: {
                width: 500,
            },
            [theme.breakpoints.down('xs')]: {
                width: 300,
            },
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            marginTop: '2rem',
        },
    },
    secondBanner: {
        padding: '5rem 0',
        marginTop: '5rem',
        backgroundImage: `url("${blurCover}")`,
        backgroundSize: 'cover',
    },
    detailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    detail: {
        width: 500,
        margin: '2rem 0',
        [`& h5`]: {
            fontWeight: 'bold',
        },
    },
}));

const diamondDetails = [
    'Ageing and sun damaged skin',
    'Acne and acne scarring',
    'Fine Lines',
    'Altered Pigmentation',
    'Enlarged and Clogged Pores',
    'Stretch Marks',
];

export default function DiamondElite(): ReactElement {
    const classes = useStyles();

    return (
        <>
            <Container maxWidth="lg">
                <Typography align="center" variant="h3">
                    The Diamond Effect
                </Typography>
                <Typography align="center" variant="subtitle1">
                    Most advanced and intelligent diamond micro-dermabrasion system available.
                </Typography>
                <div className={classes.firstBanner}>
                    <Typography component="h6" variant="h6">
                        The Diamond Elite system uses natural diamonds to enhance the skin, which are gentle and
                        non-toxic. The diamond tipped wand glides over the skin with a vacuum action and gently
                        polishing the skin. This is a lunch time procedure with immediate results.
                    </Typography>
                    <img src={diamondEliteImg} alt="Diamond Elite" />
                </div>
            </Container>
            <div className={classes.secondBanner}>
                <Typography align="center" color="inherit" variant="h4">
                    Microdermabrasion can be used to treat:
                </Typography>
                <Container maxWidth="lg" className={classes.detailsContainer}>
                    {diamondDetails.map((detail) => {
                        return (
                            <div className={classes.detail} key={detail}>
                                <Typography align="center" component="h5" variant="h5">
                                    {detail}
                                </Typography>
                            </div>
                        );
                    })}
                </Container>
            </div>
            <Endorsements
                sonicLink="https://www.sonicmedical.co.uk/diamond-elite-microdermabrasion/"
                nouveauLink="https://nouveauskintherapy.co.uk/"
            />
        </>
    );
}
