import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { getMedia } from '../../contentful';

const useStyles = makeStyles((theme) => ({
    gallery: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [`& img`]: {
            margin: '3rem 0',
            maxWidth: '300px',
            [theme.breakpoints.down('xs')]: {
                height: 300,
            },
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
}));

export default function Gallery(): ReactElement {
    const classes = useStyles();

    const [imageUrls, setImageUrls] = useState<string[]>([]);

    useEffect(() => {
        getMedia().then((response) => {
            setImageUrls(response);
        });
    }, []);

    return (
        <Container maxWidth="xl">
            <Typography align="center" variant="h3">
                Gallery
            </Typography>
            <div className={classes.gallery}>
                {imageUrls.map((imageUrl) => (
                    <img key={imageUrl} src={imageUrl} alt="Beautiful Woman" />
                ))}
            </div>
        </Container>
    );
}
