import React, { ReactElement, useEffect, useState } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import ListComponent from '../../components/ListComponent';
import PriceFormatter from '../../components/PriceFormatter';

import { getServices, MappedServices } from '../../contentful';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '5rem 0',
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
    },
    title: {
        marginBottom: '5rem',
    },
    headerContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        justifyContent: 'space-between',
        marginBottom: '3rem',
        [`& p`]: {
            textTransform: 'capitalize',
            cursor: 'pointer',
            [theme.breakpoints.down('xs')]: {
                padding: '1rem',
            },
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            borderBottom: 'solid 1px white',
        },
    },
    setService: {
        textDecoration: 'underline',
    },
    serviceName: {
        textTransform: 'capitalize',
        margin: '2rem 0',
    },
    subServiceContainer: {
        margin: '4rem 0',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export default function HairAndBeauty(): ReactElement {
    const classes = useStyles();
    const [services, setServices] = useState<MappedServices>();
    const [currentServiceId, setCurrentServiceId] = useState('');

    useEffect(() => {
        getServices().then((response) => {
            setServices(
                Object.fromEntries(
                    Object.entries(response).sort((serviceA, serviceB) => {
                        const sortOrder1 = serviceA[1].name === 'Hair' ? 1 : 0;
                        const sortOrder2 = serviceB[1].name === 'Hair' ? 1 : 0;

                        return sortOrder2 - sortOrder1;
                    }),
                ),
            );
        });
    }, []);

    if (!services) {
        return <></>;
    }

    const currentService = services[currentServiceId || Object.keys(services)[0]];

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <Typography className={classes.title} align="center" variant="h4">
                    Our Services
                </Typography>
                <div className={classes.headerContainer}>
                    {Object.entries(services).map(([serviceId, { name: serviceName }]) => (
                        <Typography
                            className={serviceId === currentServiceId ? classes.setService : ''}
                            component="p"
                            key={serviceId}
                            variant="subtitle1"
                            onClick={(): void => setCurrentServiceId(serviceId)}
                        >
                            {serviceName}
                        </Typography>
                    ))}
                </div>
                <Typography className={classes.serviceName} align="center" variant="h5">
                    {currentService.name}
                </Typography>
                {currentService.subServices
                    ? Object.entries(currentService.subServices)
                          .reverse()
                          .map(([serviceId, subService]) => <ListComponent key={serviceId} serviceList={subService} />)
                    : null}
                {currentService.services.reverse().map((subService) => (
                    <div className={classes.subServiceContainer} key={subService.name}>
                        <Typography variant="subtitle1">{subService.name}</Typography>
                        <Typography variant="h6">
                            <PriceFormatter price={subService.price} />
                        </Typography>
                    </div>
                ))}
                {/* TODO */}
                {/* if (isSubServiceList(subService)) {
                        return <ListComponent serviceList={subService} />;
                    } */}
            </Container>
        </div>
    );
}
