import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import sonicImg from './sonic.png';
import nouveauImg from './nouveau.png';

interface Props {
    sonicLink: string;
    nouveauLink: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        padding: '10rem 0',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            padding: '5rem 0',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    item: {
        width: 400,
        cursor: 'pointer',
        textAlign: 'center',
        [`& img`]: {
            width: 150,
            margin: '2rem 0',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '2rem 0',
        },
    },
    imageContainer: {
        height: 120,
    },
}));

export default function Endorsements(props: Props): ReactElement<Props> {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <div className={classes.item} onClick={() => window.open(props.nouveauLink, '_blank')}>
                    <Typography color="inherit" variant="h5">
                        Endorsed by Nouveau
                    </Typography>
                    <div className={classes.imageContainer}>
                        <img alt="Nouveau" src={nouveauImg} />
                    </div>
                    <Typography variant="h6">Learn more</Typography>
                </div>
                <div className={classes.item} onClick={() => window.open(props.sonicLink, '_blank')}>
                    <Typography color="inherit" variant="h5">
                        Developed by Sonic Medical
                    </Typography>
                    <div className={classes.imageContainer}>
                        <img alt="Nouveau" src={sonicImg} />
                    </div>
                    <Typography variant="h6">Learn more</Typography>
                </div>
            </Container>
        </div>
    );
}
