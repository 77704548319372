import { Box, makeStyles, Modal, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { getOffer, OfferEntry } from '../../contentful';

const useStyles = makeStyles((theme) => ({
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 800,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: '24',
        padding: '2rem',
        textAlign: 'center',
        fontWeight: 'bold',
        [`& h2`]: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.5rem',
            },
        },
        [`& p`]: {
            fontWeight: 'bold',
            marginTop: '1rem',
        },
    },
}));

export default function OfferModal(): ReactElement {
    const classes = useStyles();
    const [offer, setOffer] = useState<OfferEntry>();

    useEffect(() => {
        getOffer().then((offer) => {
            if (offer.active) {
                setOffer(offer);
            }
        });
    }, []);

    return (
        <Modal
            open={Boolean(offer)}
            onClose={() => setOffer(undefined)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.box}>
                <Typography color="primary" variant="h3" component="h2">
                    {offer?.description}
                </Typography>
                <Typography component="p" variant="h4">
                    {offer?.code}
                </Typography>
            </Box>
        </Modal>
    );
}
