import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '2rem 0',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        [`& h5`]: {
            fontFamily: 'EdwardianScriptITC',
            fontSize: '2rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '1.5rem',
            },
        },
        [`& h6`]: {
            [theme.breakpoints.down('md')]: {
                fontSize: '1.2rem',
            },
        },
    },
}));

export default function Footer(): ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth="lg">
                <Typography component="h5" variant="h5">
                    Beautiful Woman Ruislip
                </Typography>
                <Typography component="h6" variant="h6">
                    <a
                        href="https://www.google.com/maps/place/Beautiful+Woman+Ruislip/@51.5575592,-0.3968898,15z/data=!4m5!3m4!1s0x0:0x30ae97c1da6a654d!8m2!3d51.5575592!4d-0.3968898"
                        target="_blank"
                        rel="noreferrer"
                    >
                        10 Long Dr, South Ruislip, <br /> Ruislip HA4 0HG
                    </a>
                </Typography>
            </Container>
        </div>
    );
}
