import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '3rem 0',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '2.3rem',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.8rem',
        },
    },
    navContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 700,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    headerLink: {
        textDecoration: 'none',
        color: 'inherit',
        [`&:hover`]: {
            textDecoration: 'underline',
        },
    },
    menuIcon: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    mobileMenu: {
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 1,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileLinkText: {
        margin: '1rem 0',
    },
}));

const headerLinks = [
    {
        name: 'A Lift',
        link: '/aLift',
    },
    {
        name: 'Diamond Elite',
        link: '/diamondElite',
    },
    {
        name: 'Hair and Beauty',
        link: '/hairBeauty',
    },
    {
        name: 'Gallery',
        link: '/gallery',
    },
    {
        name: 'Contact',
        link: '/contact',
    },
];

export default function Header(): ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <div className={classes.root}>
            <Container className={classes.container} maxWidth="xl">
                <Typography onClick={() => history.push('/')} className={classes.title} variant="h1">
                    Beautiful Woman Ruislip
                </Typography>
                <div className={classes.navContainer}>
                    {headerLinks.map(({ name, link }) => (
                        <Link className={classes.headerLink} key={name} to={link}>
                            <Typography variant="h6">{name}</Typography>
                        </Link>
                    ))}
                </div>
                <MenuIcon onClick={(): void => setShowMobileMenu(true)} className={classes.menuIcon} />
                {showMobileMenu && (
                    <div className={classes.mobileMenu}>
                        <Container maxWidth="md">
                            {headerLinks.map(({ name, link }) => (
                                <Link
                                    onClick={(): void => setShowMobileMenu(false)}
                                    className={classes.headerLink}
                                    key={name}
                                    to={link}
                                >
                                    <Typography className={classes.mobileLinkText} align="center" variant="h5">
                                        {name}
                                    </Typography>
                                </Link>
                            ))}
                        </Container>
                    </div>
                )}
            </Container>
        </div>
    );
}
