import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

interface PriceFormaterProps {
    price: number;
}

export default function PriceFormater(props: PriceFormaterProps): ReactElement<PriceFormaterProps> {
    return <NumberFormat fixedDecimalScale decimalScale={2} displayType={'text'} prefix={'£'} value={props.price} />;
}
