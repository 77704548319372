import { Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import MapComponent from './map';

const useStyles = makeStyles((theme) => ({
    title: {
        borderBottom: 'solid 1px lightgray',
        paddingBottom: '1rem',
        marginBottom: '1rem',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: '5rem',
        [`& h5`]: {
            fontFamily: 'EdwardianScriptITC',
            fontSize: '2rem',
        },
    },
    mapContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem',
        },
    },
    contactNumbers: {
        marginTop: '1rem',
        [`& a`]: {
            textDecoration: 'none',
            color: 'inherit',
        },
        [`& a:hover`]: {
            color: '#444',
        },
    },
}));

export default function Contact(): ReactElement {
    const classes = useStyles();

    return (
        <Container maxWidth="md">
            <Typography className={classes.title} variant="h3">
                Contact
            </Typography>
            <div className={classes.container}>
                <div>
                    <Typography component="h5" variant="h5">
                        Beautiful Woman Ruislip
                    </Typography>
                    <Typography component="h6" variant="subtitle1">
                        <a
                            href="https://www.google.com/maps/place/Beautiful+Woman+Ruislip/@51.5575592,-0.3968898,15z/data=!4m5!3m4!1s0x0:0x30ae97c1da6a654d!8m2!3d51.5575592!4d-0.3968898"
                            target="_blank"
                            rel="noreferrer"
                        >
                            10 Long Dr, South Ruislip, <br /> Ruislip HA4 0HG
                        </a>
                    </Typography>
                    <Typography className={classes.contactNumbers} color="textSecondary" variant="subtitle2">
                        <a href="tel:(44) 2088413300">020 8841 3300</a> <br />
                    </Typography>
                </div>
                <div className={classes.mapContainer}>
                    <MapComponent />
                </div>
            </div>
        </Container>
    );
}
