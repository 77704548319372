import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Fade, Slide } from 'react-awesome-reveal';
import coverImg from './cover.jpg';
import secondCoverImg from './cover2.jpg';
import galleryImg from './galleryImg.jpg';

const useStyles = makeStyles((theme) => ({
    banner: {
        height: '90vh',
        backgroundSize: 'cover',
        backgroundImage: `url("${coverImg}")`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
    },
    bannerTextContainer: {
        width: 600,
        textAlign: 'center',
        [`& h3`]: {
            marginBottom: '1rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '2rem',
            },
        },
        [theme.breakpoints.down('sm')]: {
            width: 300,
        },
    },
    viewRangeButton: {
        marginRight: '1rem',
    },
    secondBanner: {
        backgroundColor: theme.palette.primary.dark,
        padding: '5rem 0',
        display: 'flex',
        justifyContent: 'center',
    },
    secondBannerContainer: {
        color: 'white',
        textAlign: 'center',
        [`& h5`]: {
            marginBottom: '2rem',
        },
    },
    thirdBanner: {
        minHeight: 700,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    thirdBannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        [`& img`]: {
            height: 400,
            width: 400,
            [theme.breakpoints.down('md')]: {
                height: 300,
                width: 300,
            },
        },
        [`& h5`]: {
            width: 400,
            marginBottom: '2rem',
            [theme.breakpoints.down('md')]: {
                width: 300,
            },
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            [`& img`]: {
                marginTop: '2rem',
            },
        },
    },
    fourthBanner: {
        height: 800,
        backgroundSize: 'cover',
        backgroundImage: `url("${secondCoverImg}")`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        [`& h5`]: {
            marginBottom: '2rem',
        },
    },
}));

export default function Home(): ReactElement {
    const classes = useStyles();
    const history = useHistory();

    return (
        <>
            <div className={classes.banner}>
                <Fade triggerOnce>
                    <div className={classes.bannerTextContainer}>
                        <Typography component="h3" align="center" color="inherit" variant="h3">
                            Look and feel beautiful at affordable prices
                        </Typography>
                        <Button
                            className={classes.viewRangeButton}
                            onClick={(): void => history.push('/hairBeauty')}
                            color="primary"
                            variant="contained"
                        >
                            View Our Range
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={(): null | Window =>
                                window.open('https://widget.treatwell.co.uk/place/380088/menu/', '_blank')
                            }
                        >
                            Book Now
                        </Button>
                    </div>
                </Fade>
            </div>
            <div className={classes.secondBanner}>
                <Fade triggerOnce>
                    <Container className={classes.secondBannerContainer} maxWidth="md">
                        <Typography align="center" component="h5" variant="h5">
                            We provide premium services with a friendly and personal approach
                        </Typography>
                        <Button
                            onClick={(): null | Window =>
                                window.open('https://widget.treatwell.co.uk/place/380088/menu/', '_blank')
                            }
                            color="inherit"
                            variant="outlined"
                        >
                            Book Now On Treatwell
                        </Button>
                    </Container>
                </Fade>
            </div>
            <div className={classes.thirdBanner}>
                <Container className={classes.thirdBannerContainer} maxWidth="lg">
                    <div>
                        <Slide triggerOnce direction="left">
                            <Typography component="h5" variant="h5">
                                High quality hairdressing and beauty services.
                            </Typography>
                            <Button onClick={(): void => history.push('/gallery')} variant="outlined">
                                View our Gallery
                            </Button>
                        </Slide>
                    </div>
                    <Slide triggerOnce direction="right">
                        <img src={galleryImg} alt="Beautiful Woman Ruislip" />
                    </Slide>
                </Container>
            </div>
            <div className={classes.fourthBanner}>
                <Container maxWidth="md">
                    <Fade triggerOnce>
                        <Typography component="h5" variant="h5">
                            Still can&apos;t to find a service?
                        </Typography>
                        <Button onClick={(): void => history.push('/contact')} color="inherit" variant="outlined">
                            Contact Us
                        </Button>
                    </Fade>
                </Container>
            </div>
        </>
    );
}
