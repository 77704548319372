import React, { ReactElement } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { lime } from '@material-ui/core/colors';
import Header from '../Header';
import Footer from '../Footer';
import ScrollToTop from '../ScrollToTop';
// Pages
import Home from '../../pages/Home';
import HairAndBeauty from '../../pages/HairAndBeauty';
import Contact from '../../pages/Contact';
import Gallery from '../../pages/Gallery';
import ALift from '../../pages/ALift';
import DiamondElite from '../../pages/DiamondElite';
import OfferModal from '../OfferModal';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4a148c',
        },
        secondary: lime,
    },
    typography: {
        fontFamily: 'Poppins',
        h1: {
            fontFamily: 'EdwardianScriptITC',
        },
    },
});

function App(): ReactElement {
    return (
        <Router>
            <ScrollToTop />
            <ThemeProvider theme={theme}>
                <Header />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route exact path="/hairBeauty">
                        <HairAndBeauty />
                    </Route>
                    <Route exact path="/contact">
                        <Contact />
                    </Route>
                    <Route exact path="/gallery">
                        <Gallery />
                    </Route>
                    <Route exact path="/aLift">
                        <ALift />
                    </Route>
                    <Route exact path="/diamondElite">
                        <DiamondElite />
                    </Route>
                </Switch>
                <Footer />
                <OfferModal />
            </ThemeProvider>
        </Router>
    );
}

export default App;
