import React, { ReactElement } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { mapStyles } from './mapStyles';

const containerStyle = {
    width: '300px',
    height: '300px',
};

const center = {
    lat: 51.557725917814636,
    lng: -0.39678250339443555,
};

function MapComponent(): ReactElement {
    return (
        <LoadScript googleMapsApiKey="AIzaSyBtDOcW5ph3pkcvflf3pvKX9JUu1gQb3D8">
            <GoogleMap options={{ styles: mapStyles }} mapContainerStyle={containerStyle} center={center} zoom={15}>
                <>
                    <Marker position={center} />
                </>
            </GoogleMap>
        </LoadScript>
    );
}

export default React.memo(MapComponent);
